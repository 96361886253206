import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';

// Form
import { useForm, useFieldArray, Controller, ErrorMessage } from 'react-hook-form';
import Select from 'react-select';
import MaskedInput from "react-input-mask";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { getSettings } from '../../../actions/settings';

function OrderForm(props) {

    let quote = props.quote;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSettings());
    }, [dispatch]);

    let pZip, pState, pCity, dZip, dState, dCity;
    let quotePickupArr = quote.pickup.split(',');

    quotePickupArr.forEach(item => {
        if (item.match(/[^a-z]{2}\s[0-9]{5}/)) {
            let newArr = item.match(/[^a-z]{2}\s[0-9]{5}/)[0].split(' ');
            pState = newArr[0];
            pZip = newArr[1];
        } else if (item.match(/[0-9]{5}/)) {
            pZip = item.match(/\d+/)[0];
        } else if (item.match(/[A-Z]{2}/) && item.match(/[A-Z]{2}/) !== "US") {
            pState = item.match(/[A-Z]{2}/)[0];
        } else {
            pCity = item;
        }
    });

    let quoteDeliveryArr = quote.delivery.split(',');

    quoteDeliveryArr.forEach(item => {
        if (item.match(/[^a-z]{2}\s[0-9]{5}/)) {
            let newArr = item.match(/[^a-z]{2}\s[0-9]{5}/)[0].split(' ');
            dState = newArr[0];
            dZip = newArr[1];
        } else if (item.match(/[0-9]{5}/)) {
            dZip = item.match(/\d+/)[0];
        } else if (item.match(/[A-Z]{2}/) && item.match(/[A-Z]{2}/) !== "US") {
            dState = item.match(/[A-Z]{2}/)[0];
        } else {
            dCity = item;
        }
    });

    const [pickupCity] = useState(pCity);
    const [pickupState] = useState({ value: pState, label: pState });

    const [deliveryCity] = useState(dCity);
    const [deliveryState] = useState({ value: dState, label: dState });

    const { register, handleSubmit, errors, control, watch, setValue } = useForm({
        defaultValues: {
            customerEmail: props.quote.customerEmail
        }
    });

    const pickupStartDate = watch('pickupStartDate');

    let customerFullName = watch('customerFullName');
    let customerEmail = watch('customerEmail');

    let pickupCopyCustomer = watch('pickupCopyCustomer');
    let deliveryCopyCustomer = watch('deliveryCopyCustomer');

    let customerPrimaryPhone = watch('customerPrimaryPhone');
    let customerPrimaryPhoneIsMobile = watch('customerPrimaryPhoneIsMobile');
    let customerAltPhone = watch('customerAltPhone');
    let customerAltPhoneIsMobile = watch('customerAltPhoneIsMobile');

    if (deliveryCopyCustomer) {
        if (customerFullName) { setValue('deliveryContactName', customerFullName); }
        if (customerEmail) { setValue('deliveryEmail', customerEmail); }
        if (customerPrimaryPhone) { setValue('deliveryPrimaryPhone', customerPrimaryPhone); }
        if (customerPrimaryPhoneIsMobile) { setValue('deliveryPrimaryPhoneIsMobile', customerPrimaryPhoneIsMobile); }
        if (customerAltPhone) { setValue('deliveryAltPhone', customerAltPhone); }
        if (customerAltPhoneIsMobile) { setValue('deliveryAltPhoneIsMobile', customerAltPhoneIsMobile); }
    }


    if (pickupCopyCustomer) {
        if (customerFullName) { setValue('pickupContactName', customerFullName); }
        if (customerEmail) { setValue('pickupEmail', customerEmail); }
        if (customerPrimaryPhone) { setValue('pickupPrimaryPhone', customerPrimaryPhone); }
        if (customerAltPhone) { setValue('pickupAltPhone', customerAltPhone); }
        if (customerPrimaryPhoneIsMobile) { setValue('pickupPrimaryPhoneIsMobile', customerPrimaryPhoneIsMobile); }
    }

    const { fields, append, remove } = useFieldArray({
        control,
        name: "agents"
    });


    // Dispatch form actions
    const onSubmit = (data) => {
        data.paymentType = { value: "COD", label: "COD " };
        props.handleCreateOrder(data);
        props.setShowSpinner(false);
    }

    let states = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];
    let states_list = [];

    states.forEach(item => {
        states_list.push({ label: item, value: item });
    });

    let holidayDates = props.holidays.map(holiday => new Date(holiday.date));


    const isWeekday = date => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const getMinDate = () => {
        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0)
        let minDate = new Date(todayDate);
        minDate.setHours(0, 0, 0, 0);
        let count = 0;

        while (count < 3) {
            minDate.setDate(minDate.getDate() + 1);
            let holidayDatesStrings = holidayDates.map(holiday => holiday.toString());
            let isHoliday = holidayDatesStrings.includes(minDate.toString());
            let day = minDate.getDay();
            // Count Mon - Fri non-holidays only
            if ((day !== 0 && day !== 6) && !isHoliday) {
                count++;
            }
        }
        return minDate;
    }

    return (
        <form className="order-form" onSubmit={handleSubmit(onSubmit)}>
            <p className="required-field">* Required Field</p>

            <div className="form-group-container">
                <h3>Your Contact Information</h3>
                <div className="group-inputs">

                    <div className="form-input">
                        <label htmlFor="customerFullName">Full Name</label>
                        <input type="text" name="customerFullName" ref={register} defaultValue={quote.customerFullName} />
                    </div>

                    <div className="form-input">
                        <label htmlFor="customerEmail">Email*</label>
                        <input
                            type="text"
                            name="customerEmail"
                            ref={
                                register({
                                    required: "Please enter your email address",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Please enter a valid email address.'
                                    }
                                })
                            }

                        />
                        <ErrorMessage errors={errors} name="customerEmail">
                            {({ message }) => <p className="form-error-message" >{message}</p>}
                        </ErrorMessage>
                    </div>

                    <div className="form-input">
                        <label htmlFor="customerPrimaryPhone">Primary Phone*</label>
                        <Controller
                            as={<MaskedInput
                                mask="(999) 999-9999"
                                maskplaceholder={"_"}
                                alwaysShowMask
                            >
                            </MaskedInput>}
                            name="customerPrimaryPhone"
                            control={control}
                            rules={{ required: "A primary phone number is required." }}
                        />

                        <div className="form-input checkbox-input">
                            <input
                                type="checkbox"
                                name="customerPrimaryPhoneIsMobile"
                                ref={register}
                            />
                            <label htmlFor="customerPrimaryPhoneIsMobile" onClick={() => setValue('customerPrimaryPhoneIsMobile', true)}>Mobile Number</label>
                        </div>

                        <ErrorMessage errors={errors} name="customerPrimaryPhone">
                            {({ message }) => <p className="form-error-message" >{message}</p>}
                        </ErrorMessage>
                    </div>


                    <div className="form-input">
                        <label htmlFor="customerAltPhone">Alternative Phone</label>
                        <Controller
                            as={<MaskedInput
                                mask="(999) 999-9999"
                                maskplaceholder={"_"}
                                alwaysShowMask
                            >
                            </MaskedInput>}
                            name="customerAltPhone"
                            control={control}
                        />

                        <div className="form-input checkbox-input">
                            <input
                                type="checkbox"
                                name="customerAltPhoneIsMobile"
                                ref={register}
                            />
                            <label htmlFor="customerAltPhoneIsMobile" onClick={() => setValue('customerAltPhoneIsMobile', true)}>Mobile Number</label>
                        </div>
                    </div>
                </div>

            </div>


            <div className="form-group-container">
                <h3>Vehicle(s)</h3>

                <div className="group-inputs">

                    {
                        quote.vehicleQuotes.map((quote, idx) => {
                            return (
                                <div key={idx}>
                                    <div className="form-group-info">
                                        <p>{quote.make} {quote.model} ({(quote.operable === true || quote.operable === "Yes") ? "Operable" : "Not Operable"})</p>
                                    </div>
                                    <input
                                        type="hidden"
                                        ref={register}
                                        value={quote.make}
                                        name={`quotes[${idx}].make`} />
                                    <input
                                        type="hidden"
                                        ref={register}
                                        value={quote.model}
                                        name={`quotes[${idx}].model`} />
                                    <input
                                        type="hidden"
                                        ref={register}
                                        value={quote.pricingClass}
                                        name={`quotes[${idx}].pricingClass`} />
                                    <input
                                        type="hidden"
                                        ref={register}
                                        value={JSON.stringify(quote.calculatedQuotes)}
                                        name={`quotes[${idx}].calculatedQuotes`} />
                                    <input
                                        type="hidden"
                                        ref={register}
                                        value={quote.operable}
                                        name={`quotes[${idx}].operable`} />
                                    <input
                                        type="hidden"
                                        ref={register}
                                        value={quote.price}
                                        name={`quotes[${idx}].price`} />
                                    <div className="form-input">
                                        <label htmlFor={`quotes[${idx}].vin`}>VIN</label>
                                        <input
                                            type="text"
                                            ref={register}
                                            name={`quotes[${idx}].vin`} />
                                    </div>
                                    <div className="form-input">
                                        <label htmlFor={`quotes[${idx}].year`}>Year*</label>
                                        <input
                                            type="number"
                                            ref={register({ min: { value: 1900, message: 'Invalid year ' }, max: { value: 2500, message: 'Invalid year ' }, required: "This field is required." })}
                                            name={`quotes[${idx}].year`} />
                                        <ErrorMessage errors={errors} name={`quotes[${idx}].year`}>
                                            {({ message }) => <p className="form-error-message" >{message}</p>}
                                        </ErrorMessage>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <input
                        type="hidden"
                        ref={register}
                        value={quote.customerFullName}
                        name="customerFullName" />
                    <input
                        type="hidden"
                        ref={register}
                        value={quote.customerEmail}
                        name="customerEmail" />
                    <input
                        type="hidden"
                        ref={register}
                        value={quote.customerCode}
                        name="customerCode" />
                    <input
                        type="hidden"
                        ref={register}
                        value={quote.miles}
                        name="miles" />
                    <input
                        type="hidden"
                        ref={register}
                        value={quote.transitTime}
                        name="transitTime" />
                    <input
                        type="hidden"
                        ref={register}
                        value={quote.uniqueId}
                        name="uniqueId" />
                    <input
                        type="hidden"
                        ref={register}
                        value={quote.portalId}
                        name="portalId" />
                    <input
                        type="hidden"
                        ref={register}
                        value={quote.transportType}
                        name="transportType" />
                    <input
                        type="hidden"
                        ref={register}
                        value={JSON.stringify(quote.totalPricing)}
                        name="totalPricing" />
                </div>
            </div>


            <div className="form-group-container">
                <h3>Pickup Details</h3>

                <div className="group-inputs">


                    <div className="address">
                        <div className="form-input">
                            <label htmlFor="pickupAddress">Address*</label>
                            <input
                                type="text"
                                name="pickupAddress"
                                ref={register({ required: 'This field is required.' })}

                            />
                            <ErrorMessage errors={errors} name="pickupAddress">
                                {({ message }) => <p className="form-error-message" >{message}</p>}
                            </ErrorMessage>
                        </div>

                        {pCity &&
                            <div className="form-input">
                                <label htmlFor="pickupCity"><strong>{pickupCity}</strong></label>
                                <input
                                    type="hidden"
                                    name="pickupCity"
                                    ref={register}
                                    defaultValue={pickupCity}
                                />
                            </div>
                        }

                        {!pCity &&
                            <div className="form-input">
                                <label htmlFor="pickupCity">City*</label>
                                <input
                                    placeholder="City"
                                    type="text"
                                    name="pickupCity"
                                    ref={register({ required: 'This field is required.' })}
                                    defaultValue={pickupCity}
                                />
                                <ErrorMessage errors={errors} name="pickupCity">
                                    {({ message }) => <p className="form-error-message" >{message}</p>}
                                </ErrorMessage>
                            </div>
                        }

                        {pState &&
                            <div className="form-input">
                                <label htmlFor="pickupState"><strong>{pickupState.value}</strong></label>
                                <input
                                    type="hidden"
                                    name="pickupState"
                                    defaultValue={pickupState.value}
                                />
                            </div>
                        }

                        {!pState &&
                            <div className="form-input">
                                <label htmlFor="pickupState">State*</label>
                                <Controller
                                    as={<Select options={states_list}
                                        placeholder="State" />}
                                    name="pickupState"
                                    control={control}
                                    rules={{ required: "This field is required." }}
                                />
                                <ErrorMessage errors={errors} name="pickupState">
                                    {({ message }) => <p className="form-error-message" >{message}</p>}
                                </ErrorMessage>
                            </div>
                        }

                        {pZip &&
                            <div className="form-input">
                                <label htmlFor="pickupZip"><strong>{pZip}</strong></label>
                                <input
                                    type="hidden"
                                    name="pickupZip"
                                    ref={register}
                                    defaultValue={pZip}
                                />
                            </div>
                        }

                        {!pZip &&
                            <div className="form-input">
                                <label htmlFor="pickupZip">Zip*</label>
                                <input
                                    type="number"
                                    name="pickupZip"
                                    ref={register({ required: "This field is required." })}
                                />
                                <ErrorMessage errors={errors} name="pickupZip">
                                    {({ message }) => <p className="form-error-message" >{message}</p>}
                                </ErrorMessage>
                            </div>
                        }

                    </div>

                    <div className="form-input">
                        <label htmlFor="serviceLevel">Service Level (Pickup Window)*</label>
                        <Controller
                            as={<Select options={[{ label: "1 Day Pickup", value: "1" }, { label: "3 Day Pickup", value: "3" }, {
                                label: "5 Day Pickup", value: "5"
                            }, { label: "Standby", value: "7" }]} placeholder="Service Level" />}
                            rules={{ required: true }}
                            default="Yes"
                            name="serviceLevel"
                            control={control}
                        />
                    </div>

                    <div className="form-input">
                        <label htmlFor="pickupStartDate">Select 1st Available Pickup Date*</label>

                        <Controller
                            as={<DatePicker
                                selected={pickupStartDate}
                                name="pickupStartDate"
                                minDate={getMinDate()}
                                filterDate={isWeekday}
                                excludeDates={holidayDates}
                            />}
                            control={control}
                            rules={{ required: "Pickup Date is required." }}
                            name="pickupStartDate"
                        />

                        <p className="form-input-info"><strong>Please note:</strong> We require 2 business days between the date of booking the order and the 1st available pick up date selected. If you have a rush order that needs to be serviced less than 2 business days out from the date of booking, please contact us directly to confirm we can service the order which may be subject to a $150 RUSH fee.</p>

                        <ErrorMessage errors={errors} name="pickupStartDate">
                            {({ message }) => <p className="form-error-message" >{message}</p>}
                        </ErrorMessage>
                    </div>


                </div>


                <div className="form-input checkbox-input">
                    <input
                        type="checkbox"
                        name="pickupCopyCustomer"
                        ref={register}
                    />
                    <label htmlFor="pickupCopyCustomer">Use My Details</label>
                </div>
                <div className="group-inputs">

                    <div className="form-input">
                        <label htmlFor="pickupBusinessName">Business Name (optional)</label>
                        <input
                            type="text"
                            name="pickupBusinessName"
                            ref={register} />
                    </div>

                    <div className="form-input">
                        <label htmlFor="pickupContactName">Contact Name*</label>
                        <input
                            type="text"
                            name="pickupContactName"
                            ref={register({ required: 'This field is required.' })}
                        />
                        <ErrorMessage errors={errors} name="pickupContactName">
                            {({ message }) => <p className="form-error-message" >{message}</p>}
                        </ErrorMessage>
                    </div>
                </div>
                <div className="group-inputs">
                    <div className="form-input">
                        <label htmlFor="pickupEmail">Contact Email</label>
                        <input
                            type="text"
                            name="pickupEmail"
                            ref={
                                register({
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Please enter a valid email address.'
                                    }
                                })
                            }
                        />
                        <ErrorMessage errors={errors} name="pickupEmail">
                            {({ message }) => <p className="form-error-message" >{message}</p>}
                        </ErrorMessage>
                    </div>

                    <div className="form-input">
                        <label htmlFor="pickupPrimaryPhone">Contact Phone*</label>
                        <Controller
                            as={<MaskedInput
                                mask="(999) 999-9999"
                                maskplaceholder={"_"}
                                alwaysShowMask
                            >
                            </MaskedInput>}
                            name="pickupPrimaryPhone"
                            control={control}
                            rules={{ required: "A primary phone number is required." }}
                        />



                        <div className="checkbox-input">
                            <input
                                type="checkbox"
                                name="pickupPrimaryPhoneIsMobile"
                                ref={register}
                            />
                            <label htmlFor="pickupPrimaryPhoneIsMobile" onClick={() => setValue('pickupPrimaryPhoneIsMobile', true)}>Mobile Number</label>
                        </div>


                        <ErrorMessage errors={errors} name="pickupPrimaryPhone">
                            {({ message }) => <p className="form-error-message" >{message}</p>}
                        </ErrorMessage>
                    </div>


                    <div className="form-input">
                        <label htmlFor="pickupAltPhone">Contact Alternative Phone</label>
                        <Controller
                            as={<MaskedInput
                                mask="(999) 999-9999"
                                maskplaceholder={"_"}
                                alwaysShowMask
                            >
                            </MaskedInput>}
                            name="pickupAltPhone"
                            control={control}
                        />

                        <div className="checkbox-input">
                            <input
                                type="checkbox"
                                name="pickupAltPhoneIsMobile"
                                ref={register}
                            />
                            <label htmlFor="pickupAltPhoneIsMobile">Mobile Number</label>
                        </div>
                    </div>
                </div>
                <div className="form-input full">
                    <label htmlFor="pickupNotes">Pickup Notes</label>
                    <textarea name="pickupNotes" ref={register({ maxLength: 250 })} />
                </div>

            </div>

            <div className="form-group-container">
                <h3>Delivery Details</h3>
                <div className="group-inputs">

                    <div className="address">
                        <div className="form-input">
                            <label htmlFor="deliveryAddress">Address*</label>
                            <input
                                type="text"
                                name="deliveryAddress"
                                ref={register({ required: 'This field is required.' })}
                            />
                            <ErrorMessage errors={errors} name="deliveryAddress">
                                {({ message }) => <p className="form-error-message" >{message}</p>}
                            </ErrorMessage>
                        </div>

                        {dCity &&
                            <div className="form-input">
                                <label htmlFor="deliveryCity"><strong>{dCity}</strong></label>
                                <input
                                    type="hidden"
                                    name="deliveryCity"
                                    ref={register}
                                    defaultValue={dCity}
                                />
                            </div>
                        }

                        {!dCity &&
                            <div className="form-input">
                                <label htmlFor="deliveryCity">City*</label>
                                <input
                                    type="text"
                                    name="deliveryCity"
                                    ref={register({ required: 'This field is required.' })}
                                    defaultValue={deliveryCity}
                                />
                                <ErrorMessage errors={errors} name="deliveryCity">
                                    {({ message }) => <p className="form-error-message" >{message}</p>}
                                </ErrorMessage>
                            </div>
                        }

                        {dState &&
                            <div className="form-input">
                                <label htmlFor="deliveryState"><strong>{dState}</strong></label>
                                <input
                                    type="hidden"
                                    name="deliveryState"
                                    defaultValue={deliveryState.value}
                                    ref={register}
                                />
                            </div>
                        }

                        {!dState &&
                            <div className="form-input">
                                <label htmlFor="deliveryState">State*</label>
                                <Controller
                                    as={<Select options={states_list}
                                        placeholder="State" />}
                                    name="deliveryState"
                                    control={control}
                                    rules={{ required: "This field is required." }}
                                />
                                <ErrorMessage errors={errors} name="deliveryState">
                                    {({ message }) => <p className="form-error-message" >{message}</p>}
                                </ErrorMessage>
                            </div>
                        }


                        {dZip &&
                            <div className="form-input">
                                <label htmlFor="deliveryZip"><strong>{dZip}</strong></label>
                                <input
                                    type="hidden"
                                    name="deliveryZip"
                                    ref={register}
                                    defaultValue={dZip}
                                />
                            </div>
                        }

                        {!dZip &&
                            <div className="form-input">
                                <label htmlFor="deliveryZip">Zip*</label>
                                <input
                                    type="number"
                                    name="deliveryZip"
                                    ref={register({ required: "This field is required." })}
                                />
                                <ErrorMessage errors={errors} name="deliveryZip">
                                    {({ message }) => <p className="form-error-message" >{message}</p>}
                                </ErrorMessage>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="form-input checkbox-input">
                <input
                    type="checkbox"
                    name="deliveryCopyCustomer"
                    ref={register}
                />
                <label htmlFor="deliveryCopyCustomer">Use My Details</label>
            </div>

            <div className="group-inputs">

                <div className="form-input">
                    <label htmlFor="deliveryBusinessName">Business Name</label>
                    <input
                        type="text"
                        name="deliveryBusinessName"
                        ref={register} />
                </div>
                <div className="form-input">
                    <label htmlFor="deliveryContactName">Contact Name*</label>
                    <input
                        type="text"
                        name="deliveryContactName"
                        ref={register({ required: 'This field is required.' })}
                    />
                    <ErrorMessage errors={errors} name="deliveryContactName">
                        {({ message }) => <p className="form-error-message" >{message}</p>}
                    </ErrorMessage>
                </div>

            </div>
            <div className="group-inputs">
                <div className="form-input">
                    <label htmlFor="deliveryEmail">Contact Email</label>
                    <input
                        type="text"
                        name="deliveryEmail"
                        ref={
                            register({
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Please enter a valid email address.'
                                }
                            })
                        }
                    />
                    <ErrorMessage errors={errors} name="deliveryEmail">
                        {({ message }) => <p className="form-error-message" >{message}</p>}
                    </ErrorMessage>
                </div>

                <div className="form-input">
                    <label htmlFor="deliveryPrimaryPhone">Contact Primary Phone*</label>
                    <Controller
                        as={<MaskedInput
                            mask="(999) 999-9999"
                            maskplaceholder={"_"}
                            alwaysShowMask
                        >
                        </MaskedInput>}
                        name="deliveryPrimaryPhone"
                        control={control}
                        rules={{ required: "A primary phone number is required." }}
                    />

                    <div className="form-input checkbox-input">
                        <input
                            type="checkbox"
                            name="deliveryPrimaryPhoneIsMobile"
                            ref={register}
                        />
                        <label htmlFor="deliveryPrimaryPhoneIsMobile" onClick={() => setValue('deliveryPrimaryPhoneIsMobile', true)}>Mobile Number</label>
                    </div>


                    <ErrorMessage errors={errors} name="deliveryPrimaryPhone">
                        {({ message }) => <p className="form-error-message" >{message}</p>}
                    </ErrorMessage>
                </div>


                <div className="form-input">
                    <label htmlFor="deliveryAltPhone">Contact Alternative Phone</label>
                    <Controller
                        as={<MaskedInput
                            mask="(999) 999-9999"
                            maskplaceholder={"_"}
                            alwaysShowMask
                        >
                        </MaskedInput>}
                        name="deliveryAltPhone"
                        control={control}
                    />

                    <div className="form-input checkbox-input">
                        <input
                            type="checkbox"
                            name="deliveryAltPhoneIsMobile"
                            ref={register}
                        />
                        <label htmlFor="deliveryAltPhoneIsMobile"
                            onClick={() => setValue('deliveryAltPhoneIsMobile', true)}>Mobile Number</label>
                    </div>

                </div>

            </div>

            <div className="form-input full">
                <label htmlFor="deliveryNotes">Delivery Notes</label>
                <textarea name="deliveryNotes" ref={register({ maxLength: 250 })} />
            </div>


            {
                (Object.keys(errors).length !== 0) &&
                <div className="form-errors-list">
                    <p>Please complete the following required fields to book your order.</p>
                    <ul>
                        {errors.reg ? <li>Registration #</li> : ''}
                        {errors.pickupAddress ? <li>Pickup Address</li> : ''}
                        {errors.pickupContactName ? <li>Pickup Contact Name</li> : ''}
                        {errors.pickupStartDate ? <li>Pickup Start Date</li> : ''}
                        {errors.pickupPrimaryPhone ? <li>Pickup Phone</li> : ''}
                        {errors.deliveryAddress ? <li>Delivery Address</li> : ''}
                        {errors.deliveryContactName ? <li>Delivery Contact Name</li> : ''}
                        {errors.deliveryPrimaryPhone ? <li>Delivery Phone</li> : ''}
                        {errors.customerPrimaryPhone ? <li>Primary Phone</li> : ''}
                        {errors.serviceLevel ? <li>Service Level</li> : ''}
                        {errors.quotes && errors.quotes.length > 0 ? <li>Vehicle Year</li> : ''}
                    </ul>
                </div>
            }

            <div className="button-container">
                <button className="form-submit" type="submit">Submit Order</button>
            </div>

        </form >
    )
}

export default connect()(OrderForm);