import React from "react";

export default function Header(props) {
  return (
    <header id="header">
      <div className="app-heading">
        <img
          id="mccollisters-logo"
          alt="McCollister's Logo"
          src="https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mccollisters-auto-logistics.png"
        />
        <h3>
          Call now! <a href="tel:123-456-7890">888-819-0594</a>
        </h3>
      </div>
    </header>
  );
}
