import React from "react";
import { connect } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

// Actions
import { getQuote } from "../../actions/quote";

// Form
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";
import fireTrackingScript from "../../utils/fireTrackingScript";

function QuoteFind(props) {
  const { register, handleSubmit, errors } = useForm({});

  let history = useHistory();

  const onSubmit = (data) => {
    fireTrackingScript("Find Your Quote");
    props.dispatch(getQuote(data, () => history.push("/app/quote")));
  };

  return (
    <form id="find-form" onSubmit={handleSubmit(onSubmit)}>
      {props.quote && props.quote.error && (
        <div className="error">{props.quote.error}</div>
      )}

      <div className="form-group-container">
        <div className="form-input">
          <label htmlFor="customerCode">Confirmation Code</label>
          <input type="text" name="customerCode" ref={register} />
        </div>

        <div className="form-input">
          <label htmlFor="customerEmail">Your Last Name</label>
          <input type="text" name="customerLastName" ref={register} />

          <ErrorMessage errors={errors} name="customerEmail">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
        </div>
      </div>

      <div className="button-container">
        <button className="form-submit" type="submit">
          Find Your Quote
        </button>
      </div>

      <div className="link-container">
        <NavLink to="/quote">Get a new quote here.</NavLink>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    quote: state.quote,
  };
};

export default connect(mapStateToProps)(QuoteFind);
