import React, { useEffect } from "react";

const fireTrackingScript = (event) => {
  if (!event) return;
  // console.log("firing tracking script for: ", event);
  switch (event) {
    case "Find Your Quote":
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "formSubmission",
        formID: "Find Your Quote",
      });
      break;
    case "Book Order":
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "formSubmission",
        formID: "Book Order",
      });
      break;
    case "Get Quote":
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "formSubmission",
        formID: "Find Your Quote",
      });
      break;
    default:
      break;
  }
};

export default fireTrackingScript;
