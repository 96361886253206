// Redux actions namespacing

// Quotes.js
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const UPDATE_QUOTE = 'ADD_QUOTE';
export const GET_QUOTES = 'GET_QUOTES';
export const GET_QUOTE = 'GET_QUOTE';
export const QUOTE_ERROR = 'QUOTE_ERROR';
export const CLEAR_QUOTE_ERROR = 'CLEAR_QUOTE_ERROR';
export const REMOVE_QUOTE = 'CLEAR_QUOTE_ERROR';

// Orders.js
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_ACTIVITIES = 'GET_ORDER_ACTIVITIES';
export const REMOVE_ORDER_FILE = 'REMOVE_ORDER_FILE';
export const ORDER_ERROR = 'ORDER_ERROR';
export const CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const GET_ORDER_STATUS = 'GET_ORDER_STATUS';
export const TRACK_ORDER = 'TRACK_ORDER';
export const ORDER_TABLE_FILTERS = 'ORDER_TABLE_FILTERS';
export const CLEAR_ORDER_TABLE_FILTERS = 'CLEAR_ORDER_TABLE_FILTERS';
export const REQUEST_DRIVER_LOCATION = 'REQUEST_DRIVER_LOCATION';
export const EXPORT_ORDERS = 'EXPORT_ORDERS';
export const CLEAR_CSV = 'CLEAR_CSV';
export const ORDERS_ANALYTICS = 'ORDERS_ANALYTICS';

// Portals.js
export const CREATE_PORTAL = 'CREATE_PORTAL';
export const GET_PORTALS = 'GET_PORTALS';
export const GET_PORTAL = 'GET_PORTAL';
export const UPDATE_PORTAL = 'UPDATE_PORTAL';
export const ADMIN_GET_PORTAL = 'ADMIN_GET_PORTAL';
export const PORTAL_ERROR = 'PORTAL_ERROR';
export const CLEAR_PORTAL_ERROR = 'CLEAR_PORTAL_ERROR';

// Users.js
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ADMIN_GET_USER = 'ADMIN_GET_USER';

// Auth.js
export const AUTHORIZE_USER = 'AUTHORIZE_USER';
export const UNAUTHORIZE_USER = 'UNAUTHORIZE_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';
export const AUTH_MESSAGE = 'AUTH_MESSAGE';
export const CLEAR_AUTH_MESSAGE = 'CLEAR_AUTH_MESSAGE';

// Files.js
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_LOGO = 'UPLOAD_LOGO';
export const CLEAR_FILES = 'CLEAR_FILES';
export const ADD_ORDER_FILES = 'ADD_ORDER_FILES';


// Settings.js
export const ADMIN_GET_SETTINGS = 'ADMIN_GET_SETTINGS';
export const GET_SETTINGS = 'GET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const FILTER_PORTAL = 'FILTER_PORTAL';
export const GET_MAKES = 'GET_MAKES';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';
export const CLEAR_SETTINGS_ERROR = 'CLEAR_SETTINGS_ERROR';

// Alerts.js
export const ADD_ALERT = 'ADD_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

// Messages.js
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const SEND_EMAIL = 'SEND_EMAIL';
export const GET_EMAIL_LOGS = 'GET_EMAIL_LOGS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';
export const CLEAR_SEND_EMAIL_ERROR = 'CLEAR_SEND_EMAIL_ERROR';

export const SUBMIT_SURVEY = 'SUBMIT_SURVEY';