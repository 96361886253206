import axios from "axios";
import {
  GET_SETTINGS,
  UPDATE_SETTINGS,
  GET_MAKES,
  FILTER_PORTAL,
  SETTINGS_ERROR,
  CLEAR_SETTINGS_ERROR,
} from "./types";

import config from "../config/keys";
const ROOT = config.rootUrl;
// const ALT = config.altUrl;
const ALT = config.rootUrl;

export const filterPortal = (data) => ({
  type: FILTER_PORTAL,
  portal: data,
});

export const getMakes = () => {
  return (dispatch) => {
    return axios
      .get(`${ALT}/makes`)
      .then((response) => {
        if (response.data.error) {
          dispatch(settingsError(response.data.error));
        } else {
          dispatch(getMakesSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(settingsError("There was an error loading makes and models."));
        throw error;
      });
  };
};

export const getMakesSuccess = (data) => ({
  type: GET_MAKES,
  makes: data,
});

export const getSettings = () => {
  return (dispatch) => {
    return axios
      .get(`${ALT}/settings`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(settingsError(response.data.error));
        } else {
          dispatch(getSettingsSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(settingsError("There was an error loading portal settings."));
        throw error;
      });
  };
};

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS,
  settings: data,
});

export const updateSettings = (formData, role, callback) => {
  return (dispatch) => {
    if (role === "MCAdmin") {
      return axios
        .put(`${ROOT}/mcadmin/settings`, formData, {
          headers: { Authorization: "JWT " + localStorage.getItem("token") },
        })
        .then((response) => {
          if (response.data.error) {
            dispatch(settingsError(response.data.error));
          } else {
            dispatch(updateSettingsSuccess(response.data));
            callback();
          }
        })
        .catch((error) => {
          dispatch(
            settingsError("There was an error loading portal settings.")
          );
          throw error;
        });
    } else {
      return axios
        .get(`${ROOT}/settings`, formData, {
          headers: { Authorization: "JWT " + localStorage.getItem("token") },
        })
        .then((response) => {
          dispatch(updateSettingsSuccess(response.data));
          callback();
        })
        .catch((error) => {
          dispatch(
            settingsError("There was an error updating portal settings.")
          );
          throw error;
        });
    }
  };
};

export const updateSettingsSuccess = (data) => ({
  type: UPDATE_SETTINGS,
  settings: data,
});

export function settingsError(error) {
  return {
    type: SETTINGS_ERROR,
    payload: error,
  };
}

export function clearSettingsError() {
  return {
    type: CLEAR_SETTINGS_ERROR,
  };
}
