import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from './routers/AppRouter';
import { store } from './store/configureStore';
import HttpsRedirect from 'react-https-redirect';

export default () => {
  return (
    <HttpsRedirect>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </HttpsRedirect>
  )
}