import React from "react";

import {
  FaAward,
  FaMagic,
  FaMoneyBillWave,
  FaLock,
  FaLifeRing,
  FaBroadcastTower,
  FaPhone,
  FaLandmark,
  FaBars,
  FaColumns,
  FaUserFriends,
  FaCalculator,
  FaCalendar,
  FaCar,
  FaCogs,
  FaUserCircle,
} from "react-icons/fa";

export const AwardWinning = () => {
  return (
    <div className="value-prop">
      <span>
        <FaAward />
      </span>

      <h3>
        Award Winning <br /> Customer Service
      </h3>

      <div>
        <p>
          Our team loves ensuring that our customers have the best experience
          possible. We know how stressful shipping a vehicle can be and we are
          there to make sure the process goes smoothly and hassle-free. We are a
          white glove, concierge style service offering peace of mind.
        </p>
      </div>
    </div>
  );
};

export const ZeroUpfront = () => {
  return (
    <div className="value-prop">
      <span>
        <FaMoneyBillWave />
      </span>
      <h3>
        Zero Upfront <br /> Payment
      </h3>

      <div>
        <p>
          Yup, that is not a typo! We only require payment prior to your vehicle
          being delivered.
        </p>
      </div>
    </div>
  );
};

export const InsuranceCoverage = () => {
  return (
    <div className="value-prop">
      <span>
        <FaLifeRing />
      </span>
      <h3>
        Insurance <br /> Coverage
      </h3>

      <div>
        <p>
          Insurance is included with your transport quote. We offer a minimum
          guarantee of $50K per vehicle via OPEN carrier transport and $100K per
          vehicle via ENCLOSED carrier transport. Need more than $100K in
          insurance? We can do that too! Just contact us to discuss your needs
          as we have a specialized network to handle higher valued vehicles.
        </p>
      </div>
    </div>
  );
};

export const ThereForYou = () => {
  return (
    <div className="value-prop">
      <span>
        <FaPhone />
      </span>
      <h3>
        We're There <br /> for You
      </h3>

      <div>
        <p>
          Our regular business hours are 8 am to 6 pm Eastern time M-F, but we
          have an after-hours team that is on-call and available 7 days a week 8
          am to 1 am to ensure that we are there when you need us!
        </p>
      </div>
    </div>
  );
};

export const LiveUpdates = () => {
  return (
    <div className="value-prop">
      <span>
        <FaBroadcastTower />
      </span>
      <h3>
        Live Updates in <br /> AutoVista Portal
      </h3>

      <div>
        <p>
          Our customer portal offers real-time updates as your vehicle
          progresses to destination. Available 24/7 and just a few clicks away.
          In addition, we send text notifications to provide updated information
          and timing for pick up and deliveries. Transparency is our pledge to
          you.
        </p>
      </div>
    </div>
  );
};

export const Experience = () => {
  return (
    <div className="value-prop">
      <span>
        <FaLandmark />
      </span>
      <h3>
        Experience <br />
        <br />
      </h3>

      <div>
        <p>
          McCollister’s has been around since 1945. When you have been around
          that long, you know a thing or two about how to be successful in
          servicing customers. No matter what kind of vehicle you need to
          transport, we’re the ones you can always count on.
        </p>
      </div>
    </div>
  );
};
