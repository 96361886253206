import React from "react";
import { SocialIcon } from "react-social-icons";
// import "./Footer.scss";

import fb from "../../assets/fb.jpg";
import ig from "../../assets/ig.jpg";
import li from "../../assets/li.jpg";

export default function Footer(props) {
  return (
    <footer id="footer">
      <div className="row">
        <div className="small-12 columns">
          {/* <div className="row"></div> */}
          <div className="row content">
            <div className="large-3 medium-12 columns">
              <h4>Call Today</h4>
              {/* <br /> */}
              <a style={{ fontSize: "1.6em" }} href="tel:888-819-0594">
                <strong>888-819-0594</strong>
              </a>
            </div>
            <div className="large-9 medium-12 columns text-center">
              <p>
                <a href="/privacy-policy/">Privacy Policy</a> |
                <a href="https://www.mccollisters.com/about/"> About</a> |
                <a href="https://www.mccollisters.com/fulfillment/">
                  {" "}
                  Logistics/Warehousing
                </a>{" "}
                |
                <a href="https://www.mccollisters.com/services/white-glove/">
                  {" "}
                  Specialty Transportation
                </a>{" "}
                |<a href="https://www.mccollisters.com/news/"> News</a> |
                <a href="https://www.mccollisters.com/contact-us/">
                  {" "}
                  Contact Us
                </a>{" "}
                |
                <a href="https://www.mccollisters.com/locations/">
                  {" "}
                  Locations
                </a>{" "}
                |<a href="https://www.mccollisters.com/careers/"> Careers</a> |
                <a
                  href="http://ebusiness.unigroupinc.net/Ebusiness/jsp/Nettrack/codsel_ind.jsp?agent=u594"
                  target="_blank"
                  rel="noopener"
                >
                  {" "}
                  Track
                </a>{" "}
                |
                <a
                  href="https://www.convergepay.com/hosted-payments/?ssl_txn_auth_token=zRO%2B20lqQF24azfUhF4yCwAAAWjJp6wu#!/payment-method"
                  target="_blank"
                  rel="noopener"
                >
                  {" "}
                  Make A Payment
                </a>{" "}
                |
                <a
                  href="https://mccollisters.vendors.striven.com/"
                  target="_blank"
                  rel="noopener"
                >
                  {" "}
                  Driver Login
                </a>{" "}
                |
                <a href="https://helpdesk.mccollisters.com/support/home">
                  {" "}
                  Submit a Ticket
                </a>{" "}
                |
                <a href="https://estore.thecorporateshop.com/shopmccollisters/default.aspx">
                  {" "}
                  Apparel
                </a>
              </p>
            </div>
          </div>
          <div className="social text-center">
            <p>
              <a
                href="https://www.facebook.com/McCollisters"
                target="_blank"
                rel="me noopener"
              >
                <img
                  title="Friend Us On Facebook"
                  src={fb}
                  alt="Friend Us On Facebook"
                  border="0"
                />
              </a>

              <a
                href="https://www.instagram.com/mccollistersautologistics/"
                target="_blank"
                rel="me noopener"
              >
                <img
                  title="Find us on Instagram"
                  src={ig}
                  alt="Instagram"
                  border="0"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/mccollister's-transportation/about/"
                target="_blank"
                rel="me noopener"
              >
                <img
                  title="Follow us on LinkedIn"
                  src={li}
                  alt="Follow us on LinkedIn"
                  border="0"
                />
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="bgBlack white padT20 padB20" role="contentinfo">
        <div className="row">
          <div className="columns large-12 text-center PadB15">
            <div id="copyright">
              McCollister's Transportation Group 2022. All Rights Reserved.{" "}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
