import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Views
import Quote from "../views/Quote/Quote";
import Order from "../views/Order/Order";
import Find from "../views/Find/Find";

// Component
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

// Global Styles
import "../styles/styles.scss";

function AppRouter(props) {
  return (
    <Router>
      <div className="app">
        <Header />

        <div className="app-container">
          <div className="view-container">
            <Switch>
              <Route path="/quote">
                <Quote />
              </Route>
              <Route path="/order">
                <Order />
              </Route>
              <Route path="/book">
                <Find />
              </Route>
              <Route path="/">
                <Quote />
              </Route>
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default AppRouter;
