import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./HowToCarousel.scss";

import { BsLightningChargeFill } from "react-icons/bs";
import { FaTruckPickup } from "react-icons/fa";
import { GiStorkDelivery } from "react-icons/gi";

// import required modules
import { Pagination, Navigation } from "swiper";

export default (props) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      rewind={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="slide-container">
          <div className="slide-inner">
            <div className="slide-left">
              <div className="slide-left-top">
                <span>1</span>
                <h1>
                  Get an
                  <br />
                  Instant Quote
                </h1>
              </div>

              <p>
                Use our lightning fast quote calculator above to get the best
                options for transporting your vehicle. No pressure at all and
                choose to have the quote emailed to you for future reference
                (our quotes are good for 30 days) or go ahead and book directly
                now! Any questions, feel free chat with our team who would be
                happy to assist you.
              </p>
            </div>
            <div className="slide-right">
              <div className="icon-container step1"></div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slide-container">
          <div className="slide-inner">
            <div className="slide-left">
              <div className="slide-left-top">
                <span>2</span>
                <h1>
                  We Pick up <br />
                  Your Vehicle
                </h1>
              </div>
              <p>
                We guarantee to pick up your vehicle on the selected date or
                dates you choose when booking your order. Your assigned driver
                will give advanced notice prior to arrival and then complete a
                simple inspection of your vehicle and load it on the truck!
                <br /> <br /> <br /> <br />
              </p>
            </div>
            <div className="slide-right">
              <div className="icon-container step2"></div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slide-container">
          <div className="slide-inner">
            <div className="slide-left">
              <div className="slide-left-top">
                <span>3</span>
                <h1>
                  Vehicle
                  <br /> Delivery
                </h1>
              </div>

              <p>
                We know you have been anxiously awaiting the safe delivery of
                your vehicle and we look forward to making that happen! You can
                track the progress of your vehicle while in transit by using our
                amazing portal that gets updated in real time. In addition, your
                assigned driver will give you advanced notice to make sure that
                you are ready to accept your vehicle before arrival.
              </p>
            </div>
            <div className="slide-right">
              <div className="icon-container step3"></div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
