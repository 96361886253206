import {
  CREATE_QUOTE,
  GET_QUOTES,
  UPDATE_QUOTE,
  GET_QUOTE,
  QUOTE_ERROR,
  CLEAR_QUOTE_ERROR,
  UNAUTHORIZE_USER,
} from "../actions/types";
export default (state = { quote: null, error: null }, action) => {
  switch (action.type) {
    case "CREATE_QUOTE":
      return { ...state, quote: action.quote };
    case "CLEAR_QUOTE":
      return { state: { quote: null, error: null } };
    case "GET_QUOTE":
      return { ...state, quote: action.quote };
    case UPDATE_QUOTE:
      return {
        ...state,
        quote: action.updatedQuote,
      };
    case "QUOTE_ERROR":
      return { ...state, error: action.payload };
    case "CLEAR_QUOTE_ERROR":
      return { ...state, error: "" };
    case "RESET_QUOTE_STATE":
      return { quote: null, error: null };
    default:
      return state;
  }
};
