import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import one from "../../assets/testimonials/1.png";
import two from "../../assets/testimonials/2.png";
import three from "../../assets/testimonials/3.png";
import four from "../../assets/testimonials/4.png";
import five from "../../assets/testimonials/5.png";
import six from "../../assets/testimonials/6.png";

import eight from "../../assets/testimonials/8.png";
import nine from "../../assets/testimonials/9.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./TestimonialCarousel.scss";

// import required modules
import { Pagination, Navigation } from "swiper";

export default function Carousel(props) {
  return (
    <Swiper
      // slidesPerView={2}
      spaceBetween={5}
      // navigation={true}
      pagination={{
        clickable: true,
      }}
      rewind={true}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
      breakpoints={{
        767: {
          slidesPerView: 2,
        },
        1: {
          slidesPerView: 1,
        },
      }}
    >
      <SwiperSlide>
        <img src={one} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={two} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={three} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={four} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={five} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={six} />
      </SwiperSlide>
      {/* <SwiperSlide>
        <img src={seven} />
      </SwiperSlide> */}
      <SwiperSlide>
        <img src={eight} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={nine} />
      </SwiperSlide>
    </Swiper>
  );
}
